import { gql } from '@apollo/client';

const GET_RESULTS_DATA = gql`
  query GetResultsData($occupationCode: String!, $industryCode: String!, $zipCode: String!) {
    area(zipCode: $zipCode){
      areaCode
      wageEstimate(occupationCode: $occupationCode){
        hourlyMeanWage
        annualMeanWage
        hourly10Percentile
        hourly90Percentile
        annual10Percentile
        annual90Percentile
        locQuotient
      }
      averageWageEstimate:wageEstimate(occupationCode: "00-0000") {
        hourlyMeanWage
        annualMeanWage
      }
    }
    col(zipCode: $zipCode) {
      monthlyTotal
    }
    occupation(occupationCode: $occupationCode) {
      occupationCode
      employment2019
      employment2029
      medianAnnualWage2020
      jobTitles{
        name
      }
      remoteLikelihood {
        percentFlexible
        rankFlexible
        percentChangeFlexible
        rankChangeFlexible
      }
    }
    industry(industryCode: $industryCode) {
      industryTitle
      automationPotential {
        score
        factors
      }
    }
    projection(industryCode: $industryCode, occupationCode: $occupationCode) {
      percentChange
      employment2019
      employment2029
    }
    changeFactor(industryCode: $industryCode, occupationCode: $occupationCode) {
      factor
    }
  }
`;

export default GET_RESULTS_DATA;
