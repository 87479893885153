import * as React from 'react';
import {
  Box, Heading, Stack, Stat, StatNumber, StatHelpText, Text, Divider, Skeleton,
} from '@chakra-ui/react';

import Metric from './Metric';
import SkeletonLoader from './SkeletonLoader';

const DataSegment = ({
  sectionTitle, stats, description, loading,
}) => (
  <>
    <Box py="4">
      <Heading as="h3" size="sm" mb="4">{sectionTitle}</Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
      >
        {
        stats.map(stat => {
          const { numerator, descriptor } = stat;
          return (
            <Stat key={`${sectionTitle + descriptor}-stat`}>
              <SkeletonLoader
                isLoading={loading}
                renderSkeleton={<Skeleton isLoaded={!loading} height="20px" width="50%" speed="1.5" />}
              >
                <StatNumber>{numerator}</StatNumber>
              </SkeletonLoader>

              <StatHelpText>{descriptor}</StatHelpText>
            </Stat>
          );
        })
      }
      </Stack>
      { description && <Text>{description}</Text> }
    </Box>
    <Divider mt="6" />
  </>
);

const DataPanel = ({
  title, metric, value, segments, loading, order, defaultOpen, children,
}) => (
  <Box style={{ order }}>
    <Metric
      title={title}
      metric={metric}
      value={value}
      loading={loading}
      defaultOpen={defaultOpen}
    >
      <>
        {segments.map(segment => (
          <DataSegment
            key={segment.sectionTitle}
            sectionTitle={segment.sectionTitle}
            stats={segment.stats}
            description={segment.description}
            loading={loading}
          />
        ))}
        {children}
      </>
    </Metric>
  </Box>
);

export default DataPanel;
