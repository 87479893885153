import * as React from 'react';

import {
  Box, Button, Heading, Flex, Checkbox, Center, Divider,
} from '@chakra-ui/react';
import { logEvent } from '../util';

const CTADrawerContent = ({ subHeaderText, category, options }) => (
  <>
    <Heading as="h3" size="sm" mb="4">{subHeaderText}</Heading>
    <Flex wrap="wrap" mb="8">
      {
        options.map(opt => (
          <Checkbox
            key={opt}
            onChange={e => e.target.checked && logEvent('Interest', category, e.target.labels[0].textContent)}
            flex={{ base: '1 0 100%', md: '1 0 50%' }}
            lineHeight="1.2"
            mb="3"
            alignItems="flex-start"
            sx={{ '& > .chakra-checkbox__control': { mt: 0.5 } }}
          >
            {opt}
          </Checkbox>
        ))
      }
    </Flex>
  </>
);

const CTADrawer = ({
  drawerText, headerText, layoutRef, category, contentDrawers,
}) => (
  <>
    <Box
      bg="orange.50"
      border="1px solid"
      borderColor="orange.200"
      as="details"
      py="4"
      px={{ base: 4, md: 16 }}
      mt="6"
      sx={{ '&:not([open])': { py: '2' } }}
    >
      <Button
        as="summary"
        size="sm"
        variant="ghost"
        data-open="close"
        data-close={drawerText}
        maxWidth={{ base: '80%', md: 'auto' }}
        whiteSpace="break-spaces"
        textAlign="center"
        onClick={() => logEvent('Read_More', category, 'CTA')}
      >&nbsp;
      </Button>
      <Box>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">{headerText}</Heading>
        {contentDrawers.map(drawer => (
          <CTADrawerContent
            key={`${category}-drawer-${drawer.subHeaderText}`}
            subHeaderText={drawer.subHeaderText}
            category={category}
            options={drawer.options}
          />
        ))}
        <Center>
          <Button
            size="lg"
            colorScheme="orange"
            onClick={
              () => {
                layoutRef.current.openModal();
                logEvent('Submit', category, 'CTA');
              }
            }
          >
            Submit
          </Button>
        </Center>
      </Box>
    </Box>
    <Divider mt="6" />
  </>
);

export default CTADrawer;
