const get = require('lodash.get');

const processResults = occupationData => {
  const baseData = {
    areaLocationQuotient: {
      path: 'area.wageEstimate.locQuotient',
      default: 1,
      morph: val => parseFloat(val),
    },
    industryAutomationScore: {
      path: 'industry[0].automationPotential.score',
      default: 0,
      morph: val => {
        const score = parseInt(val, 10);
        if (Number.isNaN(score)) return 0;
        return score;
      },
    },
    projectionPercentChange: {
      path: 'projection.percentChange',
      default: 0,
      morph: val => parseFloat(val),
    },
    occupationRemoteLikelihood: {
      path: 'occupation.remoteLikelihood.rankFlexible',
      default: '25+',
      morph: val => val || '25+',
    },
    occupationRemoteLikelihoodChange: {
      path: 'occupation.remoteLikelihood.rankChangeFlexible',
      default: '25+',
      morph: val => val || '25+',
    },
    jobTitles: {
      path: 'occupation.jobTitles',
      default: [],
      morph: val => val,
    },
    industryTitle: {
      path: 'industry[0].industryTitle',
      default: '',
      morph: val => val,
    },
    projectionEmployment2019: {
      path: 'projection.employment2019',
      default: 0,
      morph: val => parseFloat(val),
    },
    projectionEmployment2029: {
      path: 'projection.employment2029',
      default: 0,
      morph: val => parseFloat(val),
    },
    annualMeanWage: {
      path: 'area.wageEstimate.annualMeanWage',
      default: 0,
      morph: val => parseFloat(val),
    },
    hourlyMeanWage: {
      path: 'area.wageEstimate.hourlyMeanWage',
      default: 0,
      morph: val => parseFloat(val),
    },
    averageAnnualMeanWage: {
      path: 'area.averageWageEstimate.annualMeanWage',
      default: 0,
      morph: val => parseFloat(val),
    },
    averageHourlyMeanWage: {
      path: 'area.averageWageEstimate.hourlyMeanWage',
      default: 0,
      morph: val => parseFloat(val),
    },
    monthlyCOL: {
      path: 'col.monthlyTotal',
      default: 0,
      morph: val => parseFloat(val),
    },
  };

  const computedData = {
    occupationTitleDefault: {
      requires: ['jobTitles'],
      morph: (...vals) => {
        const [jobTitles] = vals;
        return jobTitles.length ? jobTitles[0].name : '';
      },
    },
    occupationalStability: {
      requires: ['projectionPercentChange', 'areaLocationQuotient', 'industryAutomationScore'],
      morph: (...vals) => {
        const [projectionPercentChange, areaLocationQuotient, industryAutomationScore] = vals;
        return Math.round(projectionPercentChange + 7 * areaLocationQuotient + -2.5 * industryAutomationScore);
      },
    },
    monthlyWage: {
      requires: ['annualMeanWage', 'hourlyMeanWage'],
      morph: (...vals) => {
        const [annualMeanWage, hourlyMeanWage] = vals;
        if (annualMeanWage) return Math.round(annualMeanWage / 12);
        if (hourlyMeanWage) return Math.round(hourlyMeanWage * 160);
        return 0;
      },
    },
    averageMonthlyWage: {
      requires: ['averageAnnualMeanWage', 'averageHourlyMeanWage'],
      morph: (...vals) => {
        const [averageAnnualMeanWage, averageHourlyMeanWage] = vals;
        if (averageAnnualMeanWage) return Math.round(averageAnnualMeanWage / 12);
        if (averageHourlyMeanWage) return Math.round(averageHourlyMeanWage * 160);
        return 0;
      },
    },
    discretionaryIncome: {
      requires: ['monthlyWage', 'averageMonthlyWage', 'monthlyCOL'],
      morph: (...vals) => {
        const [monthlyWage, averageMonthlyWage, monthlyCOL] = vals;
        return Math.round(monthlyWage + averageMonthlyWage - monthlyCOL);
      },
    },
    occupationFlexibilityScore: {
      requires: ['occupationRemoteLikelihood'],
      morph: (...vals) => {
        const [occupationRemoteLikelihood] = vals;
        const score = parseFloat(occupationRemoteLikelihood);
        if (Number.isNaN(score)) return 25;
        return score;
      },
    },
  };

  const values = Object.entries(baseData)
    .reduce((acc, [key, ob]) => ({
      ...acc,
      [key]: ob.morph.apply(null, [get(occupationData, ob.path, ob.default)]),
    }), {});

  return Object.entries(computedData)
    .reduce((acc, [key, ob]) => ({
      ...acc,
      [key]: ob.morph.apply(null, ob.requires.map(k => acc[k])),
    }), values);
};

export default processResults;
