import * as React from 'react';
import { Link as GLink } from 'gatsby';
import {
  Heading, Text, Link
} from '@chakra-ui/react';

const ResultsError = () => (
  <>
    <Heading size="3xl" fontWeight="100" mb="10">Something has gone wrong</Heading>
    <Text>There is a problem with the data you entered, or something is broken on our end. We have logged the issue.</Text>
    <Text><Link as={GLink} color="red" to="/">Please go back and try something different.</Link></Text>
  </>
);

export default ResultsError;
