import * as React from 'react';
import { Link } from 'gatsby';
import {
  Heading, Stack, Tag, Button, Box, Divider, Text, Skeleton, SkeletonText, Spinner,
} from '@chakra-ui/react';
import { logEvent, template } from '../util';
import SkeletonLoader from './SkeletonLoader';
import Content from './content';

const ResultsHeader = ({
  headerText, tags, variantPath, componentContent, occupationData = {}, jobTitles = [], loading,
}) => (
  <>
    <Heading size="3xl" fontWeight="100" mb="10">{headerText}</Heading>
    <SkeletonLoader isLoading={loading} renderSkeleton={<Skeleton height="20px" />}>
      <Stack direction={{base:'column', md:'row'}} spacing="2">
        {tags.map(t => <Tag key={t}>{t}</Tag>)}
        <Button variant="outline" size="sm" as={Link} to={`${variantPath}/calculator`}>Change</Button>
      </Stack>
    </SkeletonLoader>
    <Box pt="8" pb="4">
      <SkeletonLoader isLoading={loading} renderSkeleton={<SkeletonText noOfLines="4" />}>
        <Content md text={template(componentContent, occupationData)} />
        <Divider mb="4" borderColor="transparent" />
        <details>
          <Button as="summary" size="sm" variant="ghost" data-open="close" data-close="view additional job details" onClick={() => logEvent('Read_More', 'Job Titles', 'Header')}>&nbsp;</Button>
          <Text size="md" mb="2">The data for this job is the same as these other job titles:</Text>
          <Text fontSize="sm">
            { jobTitles.map(jt => jt.name).join(', ') }
          </Text>
        </details>
      </SkeletonLoader>
    </Box>
  </>
);

export default ResultsHeader;
