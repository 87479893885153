import React from 'react';

const SkeletonLoader = ({ isLoading, renderSkeleton, children }) => {
  if (isLoading) {
    return renderSkeleton;
  }
  return <>{children}</>;
};

export default SkeletonLoader;
