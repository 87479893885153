import React from 'react';
import {
  Box, Flex, Text, Heading, Button, Skeleton,
} from '@chakra-ui/react';

import Feedback from './Feedback';
import SkeletonLoader from './SkeletonLoader';

import { defaults, mapDataToText, logEvent } from '../util';
import { defaultStyles, workAheadStyles } from '../styles';

const styles = defaults(defaultStyles, workAheadStyles);

export default ({
  title, metric, value, loading, defaultOpen, children,
}) => {
  const content = mapDataToText(metric, value) || {};

  const { numerator = '', descriptor = '' } = content;

  if (!numerator) {
    return <></>;
  }

  return (
    <Flex flexDirection="column" pt="4" position="relative">
      <Heading as="h2" size="md">{title}</Heading>
      <Flex
        flex="1"
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        py={{ base: '4', md: '6' }}
      >
        <SkeletonLoader
          isLoading={loading}
          renderSkeleton={
            <Skeleton height="50px" width="33%" mr="8" speed="1.5" />
          }
        >
          <Heading sx={{
            ...styles.bigHeading, color: content.color, flex: '1 0', mr: 2,
          }}
          >{numerator}
          </Heading>
        </SkeletonLoader>
        <Text flex="1 1 33%">{descriptor}</Text>
      </Flex>
      <Box
        as="details"
        bg="whiteAlpha.600"
        px={{ base: '4', md: '8' }}
        pt="2"
        pb={{ base: '8', md: '16' }}
        mb="8"
        sx={{ '&:not([open])': { pb: '2' } }}
        open={defaultOpen}
      >
        <Button as="summary" size="sm" variant="ghost" data-open="close" data-close="read more" onClick={() => logEvent('Read_More', title, 'Body')}>&nbsp;</Button>
        <Box>
          <Feedback
            sx={{
              position: 'absolute', right: 0, mr: 4, top: 0, mt: 4,
            }}
            label={title}
          />
          {children}
        </Box>
      </Box>
    </Flex>
  );
};
