import React, { useRef, useState } from 'react';
import {
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { useQuery } from '@apollo/client';
import { parse as parseQueryString } from 'query-string';
import {
  mapDataToVariants, defaults, mapDataToText, randomizeOrder,
} from '../util';
import GET_RESULTS_DATA from '../data/resultsQuery';
import processResults from '../data/processResults';

import Layout from '../components/layout';
import ResultsHeader from '../components/ResultsHeader';
import CTADrawer from '../components/CTADrawer';
import DataPanel from '../components/DataPanel';
import ResultsError from '../components/ResultsError';

const PageResults = ({
  data, location, pageContext,
}) => {
  const { state = {}, search } = location;

  const {
    occ: occupationCode,
    ind: industryCode,
    loc: zipCode,
  } = parseQueryString(search);

  const occupationTitle = state ? state.occupationTitle : '';

  const { variantName, route, variantPath } = pageContext;
  const variants = mapDataToVariants(data.page.nodes, {
    description: 'description',
    title: 'title',
    heading: 'heading',
    order: 'order',
  });
  const content = defaults(variants.default, variants[variantName]);

  const componentVariants = mapDataToVariants(data.component.nodes, {
    text: 'text',
  });
  const componentContent = defaults(componentVariants.default, componentVariants[variantName]);

  const layoutRef = useRef();

  const variantOrder = content.order && content.order.split(',');
  const panelOrder = variantOrder || randomizeOrder('randomize-panel', [1, 2, 3]);
  const defaultOpen = variantOrder ? variantOrder.map( v => v === '1' ) : [false, false, false];
  // console.log(variantOrder, defaultOpen);

  const validEntry = occupationCode && industryCode && zipCode && /^[0-9]{5}$/.test(zipCode);

  if (!validEntry) {
    return (
      <Layout title={content.title} description={content.description} ref={layoutRef} location={location}>
        <ResultsError />
      </Layout>
    );
  }

  const metrics = data.metrics.nodes.reduce((acc, cur) => {
    const { metric, ...rest } = cur.data;
    return {
      ...acc,
      [metric]: [
        ...acc[metric] || [],
        rest,
      ],
    };
  }, {});

  const { loading, error, data: occupationData = {} } = useQuery(GET_RESULTS_DATA, {
    variables: {
      occupationCode,
      industryCode,
      zipCode,
    },
  });

  const processedResults = processResults(occupationData);

  // console.log(occupationData);
  // console.log(processedResults);

  const {
    areaLocationQuotient,
    industryAutomationScore,
    projectionPercentChange,
    occupationRemoteLikelihood,
    occupationRemoteLikelihoodChange,
    jobTitles,
    industryTitle,
    projectionEmployment2019,
    projectionEmployment2029,
    annualMeanWage,
    hourlyMeanWage,
    averageAnnualMeanWage,
    averageHourlyMeanWage,
    monthlyCOL,
    occupationTitleDefault,
    occupationalStability,
    monthlyWage,
    averageMonthlyWage,
    discretionaryIncome,
    occupationFlexibilityScore,
  } = processedResults;

  // needs a better fix for missing data, this works but breaks the data set!
  const locQuotientMetrics = mapDataToText(metrics.locationQuotient, areaLocationQuotient);
  const automationRiskMetrics = mapDataToText(metrics.automationPotential, industryAutomationScore);
  const employmentProjectionMetrics = mapDataToText(metrics.employmentProjection, projectionPercentChange);

  const jobTitle = occupationTitle || occupationTitleDefault;

  return (
    <Layout title={content.title} description={content.description} ref={layoutRef} location={location}>
      <ResultsHeader
        headerText={content.heading}
        tags={[jobTitle, industryTitle, zipCode]}
        variantPath={variantPath}
        componentContent={componentContent.text}
        occupationData={occupationData}
        jobTitles={jobTitles}
        loading={loading}
      />
      <Flex direction="column">
        <DataPanel
          title="Occupational Stability"
          metric={metrics.stability}
          value={occupationalStability}
          loading={loading}
          order={panelOrder && panelOrder[0] || 1}
          defaultOpen={defaultOpen[0]}
          segments={[
            {
              sectionTitle: 'Employment Projections',
              stats: [
                {
                  numerator: employmentProjectionMetrics.numerator,
                  descriptor: employmentProjectionMetrics.descriptor,
                },
                {
                  numerator: `${projectionPercentChange}%`,
                  descriptor: 'est. job growth',
                },
                {
                  numerator: projectionEmployment2019 * 1000,
                  descriptor: 'jobs in 2019',
                },
                {
                  numerator: projectionEmployment2029 * 1000,
                  descriptor: 'est. jobs in 2029',
                },
              ],
            },
            {
              sectionTitle: 'Job Density in Your Area',
              description: 'A location quotient is a comparison of the number of jobs within a given occupation in your area, compared to the national average. 1.0 is exactly average.',
              stats: [
                {
                  numerator: locQuotientMetrics.numerator,
                  descriptor: locQuotientMetrics.descriptor,
                },
                {
                  numerator: `${areaLocationQuotient}%`,
                  descriptor: 'location quotient',
                },
              ],
            },
            {
              sectionTitle: 'Risk of Automation',
              description: 'Risk of Automation is calculated by breaking down jobs by the amount of time spent on broad types of tasks, and then evaluating how readily available demonstrable technologies capable of executing some or all of those tasks are to the companies paying for the work.',
              stats: [
                {
                  numerator: automationRiskMetrics.numerator,
                  descriptor: automationRiskMetrics.descriptor,
                },
                {
                  numerator: `${industryAutomationScore} / 10`,
                  descriptor: 'overall risk profile',
                },
              ],
            },
          ]}
        >
          <CTADrawer
            drawerText="Want to increase the stability of your career?"
            headerText="Help us cater results to you"
            layoutRef={layoutRef}
            category="Occupational Stability"
            contentDrawers={[{
              subHeaderText: 'Would you consider any of these to increase your job stability?',
              options: [
                'Continuing Education',
                'Online Courses',
                'A short term job to gain experience',
                'An additional side job',
                'Managerial Skill Development',
                'Job Skill Development (ex: data or software)',
              ],
            }]}
          />
          <Box py="4">
            <Text fontSize="sm">Employment Projections tell us if we can expect more or less of these jobs to exist by 2029. Job Density tells us how many of that total number of jobs will be available in your area. In this case, job density is more stable becuase a high concentration in your area means you will have options if you need to change employers. Risk of Automation estimates if portions of the occupational duties are likely to be augmented or replaced by machinery. </Text>
          </Box>
        </DataPanel>

        <DataPanel
          title="Quality of Life"
          metric={metrics.discretionaryIncome}
          value={discretionaryIncome}
          loading={loading}
          order={panelOrder && panelOrder[1] || 1}
          defaultOpen={defaultOpen[1]}
          segments={[
            {
              sectionTitle: 'Estimated Discretionary Income',
              description: 'Discretionary Income numbers are based on the difference between monthly median wages provided by the Bureau of Labor Statistics (adjusted to reflect estimated Household Income) and average cost of living expenses as calculated by the Economic Policy Institute. Cost of Living data is calculated by county, which we map to zip-code.',
              stats: [
                {
                  numerator: `$${discretionaryIncome}`,
                  descriptor: 'monthly household DI',
                },
                {
                  numerator: `$${averageMonthlyWage}`,
                  descriptor: 'average wage in your location',
                },
                {
                  numerator: `$${monthlyWage}`,
                  descriptor: 'your average wage',
                },
                {
                  numerator: `$${monthlyCOL}`,
                  descriptor: 'estimated cost of living',
                },
              ],
            },
          ]}
        >
          <CTADrawer
            drawerText="Want some options to improve your Quality of Life?"
            headerText="Help us cater results to you"
            layoutRef={layoutRef}
            category="Quality of Life"
            contentDrawers={[
              {
                subHeaderText: 'We can find alternative areas for you to live that will increase disposable income. Would you prefer to see:',
                options: [
                  'Areas of the US where I can get paid the most',
                  'Similar areas with lower Costs of Living',
                  'Areas where I will have the highest disposable income',
                  'No thanks. I would prefer not to move',
                ],
              },
              {
                subHeaderText: 'We can find other occupations in your area that are likely to pay you more. Would you like to see:',
                options: [
                  'The highest paying jobs in your area',
                  'Higher paying jobs with similar skills to your current profession',
                  'Your same or similar jobs in other industries ',
                  'No thanks. I don’t want to change professions',
                ],
              },
              {
                subHeaderText: 'We can find ways for you to grow within your current occupation. Would you like to see:',
                options: [
                  'Ways to gain managerial and/or leadership skills to move up in the organization',
                  'Ways to gain skills in working with data or software that help me grow',
                  'The most common growth paths for people in my occupation',
                  'No thanks. I don’t would prefer to see other options',
                ],
              },
            ]}
          />
          <Box py="4">
            <Text fontSize="sm">Estimated Monthly Discretionary Income calculations assume a two income household, with the second wage equaling the average wage in your area, minus the estimated Cost of Living. Cost of Living estimates are for a household of 4 including 2 adults and 2 children and include location specific estimates for housing, food, childcare, healthcare, transportation, taxes and other necessities</Text>
          </Box>
        </DataPanel>

        <DataPanel
          title="Job Flexibility"
          metric={metrics.remoteLikelihood}
          value={occupationFlexibilityScore}
          loading={loading}
          order={panelOrder && panelOrder[2] || 1}
          defaultOpen={defaultOpen[2]}
          segments={[
            {
              sectionTitle: 'Job Friendliness to Flexible Arrangements',
              description: 'The data accounts for the percentage of new job postings within a job category that allow some form of flexible arrangement. While this does not represent the total number of jobs within the category thathave flexible arrangements, this as an indicator of the overall friendliness of the profession to alternative work arrangements. ',
              stats: [
                {
                  numerator: occupationRemoteLikelihood,
                  descriptor: 'ranked by role',
                },
                {
                  numerator: occupationRemoteLikelihoodChange,
                  descriptor: 'ranked by growth rate',
                },
              ],
            },
          ]}
        >
          <CTADrawer
            drawerText="Want to have a more flexible work life?"
            headerText="Help us cater results to you"
            layoutRef={layoutRef}
            category="Job Flexibility"
            contentDrawers={[{
              subHeaderText: 'What kind of flexibility at work are you looking for?',
              options: [
                'Remote work',
                'Flexible Hours',
                'Part Time',
                'Freelance or Contract',
              ],
            }]}
          />
          <Box py="4">
            <Text fontSize="sm">Rankings are based on average percent of job postings per quarter for the time period from from Q4'19 - Q3'20. Growth rate was calculated as the average quarter-over-quarter percent change for the time period from Q4'19 - Q3'20.</Text>
          </Box>
        </DataPanel>
      </Flex>
    </Layout>
  );
};

export default PageResults;

export const pageQuery = graphql`
  query ResultsPageQuery(
    $pageName: String!
  ){
    page: allAirtable(filter: {table: {eq: $pageName}}) {
      nodes {
        data {
          description
          title
          heading
          order
          variant {
            data {
              name
            }
          }
        }
      }
    }
    component: allAirtable(filter: {table: {eq: "Component_Occupation_Description"}}) {
      nodes {
        data {
          text
          variant {
            data {
              name
            }
          }
        }
      }
    }
    metrics: allAirtable(filter: {table: {eq: "Metrics"}}) {
      nodes {
        data {
          descriptor
          max
          min
          numerator
          color
          variant {
            data {
              name
            }
          }
          metric
        }
      }
    }
  }
`;
